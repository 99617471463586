<template>
  <b-card>
    <g-form>
      <!-- choose between invoice and deserved fee -->
      <b-row
        v-if="currentBranch.isTaxCollectionActivated"
        class="service-report"
      >
        <b-col
          class="gb-package offset-md-4 mb-2"
          md="4"
        >
          <b-button-group>
            <b-button
              :class="[
                'gb-btn-child',
                { btnActive: selectedItem.type === 'invoice' },
              ]"
              variant="flat-primary"
              @click="selectedItem.type = 'invoice'"
            >
              {{ $t("salesInvoices") }}
            </b-button>

            <b-button
              :class="[
                'gb-btn-child',
                { btnActive: selectedItem.type === 'deservedFee' },
              ]"
              variant="flat-primary"
              @click="selectedItem.type = 'deservedFee'"
            >
              {{ $t("deservedFees") }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- from date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <!-- to date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>

        <!-- student  -->
        <b-col md="4">
          <student-autocomplete ref="autoComlete"
                    name="student"
                    :value.sync="selectedItem.studentId"
                    url="students/getStudentsTaxLookup"
                    @change:action="(val) => this.students.push(val)"
                  />
        </b-col>

        <!-- stage  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.stageId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="stage"
              field="select"
              name="stage"
              :options="stages"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- row  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.rowId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="row"
              field="select"
              name="row"
              :options="rows"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- classroom -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.classroomId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="class"
              field="select"
              name="classroom"
              :options="classrooms"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- service  -->
        <b-col md="12">
          <b-form-group>
            <g-field
              :value.sync="selectedServices"
              multiple
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="service"
              field="select"
              name="serviceId"
              :options="services"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- different print choices -->
      <b-row>
        <b-col
          cols="12"
          class="repots-buttons"
        >
          <b-button
            v-show="selectedItem.type === 'invoice'"
            style="margin: 5px"
            variant="relief-primary"
            @click="print('stud-serv')"
          >
            <feather-icon
              class="mr-50"
              icon="UserIcon"
            />
            {{ $t("studentServices") }}
          </b-button>
          <b-button
            style="margin: 5px"
            :variant="selectedItem.type === 'invoice' ? 'outline-primary' : 'relief-primary'"
            @click="print('stud-gen-sumr')"
          >
            <feather-icon
              class="mr-50"
              icon="ListIcon"
            />
            {{ $t("studentsGeneralSummary") }}
          </b-button>
          <b-button
            v-show="selectedItem.type === 'invoice'"
            style="margin: 5px"
            variant="relief-primary"
            @click="print('serv-sumr')"
          >
            <feather-icon
              class="mr-50"
              icon="CloudLightningIcon"
            />
            {{ $t("servicesDetails") }}
          </b-button>
          <b-button
            style="margin: 5px"
            variant="outline-primary"
            @click="print('serv-gen-sumr')"
          >
            <feather-icon
              class="mr-50"
              icon="ListIcon"
            />
            {{ $t("servicesGeneralSummary") }}
          </b-button>
          <b-button
            style="margin: 5px"
            variant="relief-primary"
            @click="print('stud-serv-bal')"
          >
            <feather-icon
              class="mr-50"
              icon="DollarSignIcon"
            />
            {{ $t("studentServicesBalance") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    StudentAutocomplete,
  },
  mixins: [
    reportMixin,
  ],
  props: ['id'],
  data() {
    return {
      students: [],
      classrooms: [],
      rows: [],
      stages: [],
      services: [],
      school: [],
      selectedServices: [],
      selectedItem: {
        type: null,
        studentId: null,
        classroomId: null,
        rowId: null,
        stageId: null,
        serviceIds: null,
        fromDate: null,
        toDate: null,
        isFeesActivityActive: false
      },
    };
  },

  watch: {
    'selectedItem.studentId'(studentId) {
      this.get({ url: 'Students', id: studentId }).then((data) => {
          this.selectedItem.stageId = data.stageId
          this.selectedItem.rowId = data.row
          this.selectedItem.classroomId = data.classroomId
        })
    }
  },

  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.selectedItem.type = 'invoice'
    this.getData();
  },

  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getData() {
        this.get({ url: 'Classrooms' }).then((data) => {
          this.classrooms = data;
        })
      .then(() => {
        this.get({ url: 'services' }).then((data) => {
          this.services = data;
        })
      })
      .then(() => {
        this.get({ url: 'Rows' }).then((data) => {
          this.rows = data;
        })
      })
      .then(() => {
        this.get({ url: 'Stages' }).then((data) => {
          this.stages = data;
        })
      })
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    preparePrametersForPrint() {
      const selectedStudent = this.filterDataBaseOnId(this.students, this.selectedItem.studentId);
      const allStudents = this.filterDataBaseOnId(reportParameters, 'allStudents');
      this.selectedItem.studentName = this.selectedItem.studentId
                                    ? this.isRight ? selectedStudent.arabicName : selectedStudent.englishName
                                    : this.isRight ? allStudents.arabicName : allStudents.englishName;

      const selectedStage = this.filterDataBaseOnId(this.stages, this.selectedItem.stageId);
      const allStages = this.filterDataBaseOnId(reportParameters, 'allStages');
      this.selectedItem.stageName = this.selectedItem.stageId
                                  ? this.isRight ? selectedStage.arabicName : selectedStage.englishName
                                  : this.isRight ? allStages.arabicName : allStages.englishName;

      const selectedRow = this.filterDataBaseOnId(this.rows, this.selectedItem.rowId);
      const allRows = this.filterDataBaseOnId(reportParameters, 'allRows');
      this.selectedItem.rowName = this.selectedItem.rowId
                                ? this.isRight ? selectedRow.arabicName : selectedRow.englishName
                                : this.isRight ? allRows.arabicName : allRows.englishName;

      const selectedClassroom = this.filterDataBaseOnId(this.classrooms, this.selectedItem.classroomId);
      const allClassrooms = this.filterDataBaseOnId(reportParameters, 'allClassrooms');
      this.selectedItem.classroomName = this.selectedItem.classroomId
                                      ? this.isRight ? selectedClassroom.arabicName : selectedClassroom.englishName
                                      : this.isRight ? allClassrooms.arabicName : allClassrooms.englishName;

      this.selectedItem.serviceName = this.getSelectedServicesSTR();
      this.selectedItem.isFeesActivityActive = this.currentBranch.isStudentBalanceBasedOnFeesActivated;
    },

    getReportBasedOnType(type) {
      switch (type) {
        case 'stud-serv':
          return this.isRight ? 'StudentsServices-ar' : 'StudentsServices-en'

        case 'serv-sumr':
          return this.isRight ? 'ServicesSummary-ar' : 'ServicesSummary-en'

        case 'serv-gen-sumr':
          return this.isRight ? 'ServicesGeneralSummary-ar' : 'ServicesGeneralSummary-en'

        case 'stud-gen-sumr':
          return this.isRight ? 'StudentsServciesGeneralSummary-ar' : 'StudentsServciesGeneralSummary-en'

        case 'stud-serv-bal':
          return this.isRight ? 'StudentServicesBalance-ar' : 'StudentServicesBalance-en'

        default:
          return ''
      }
    },

    getSelectedServicesSTR() {
      let result = ''
      if (this.selectedServices.length === 0 || this.selectedServices.length === this.services.length) {
        result = this.isRight
               ? this.filterDataBaseOnId(reportParameters, 'allServices').arabicName
               : this.filterDataBaseOnId(reportParameters, 'allServices').englishName
      } else {
        for (let i = 0; i < this.selectedServices.length; i++) {
          result = result.concat(this.isRight ? this.selectedServices[i].arabicName : this.selectedServices[i].englishName);
          if (i < this.selectedServices.length - 1) {
            result = result.concat(' ، ');
          }
        }
      }
      return result
    },

    serialzeServices() {
      if (this.selectedServices.length === 0) return null

      const services = []
      this.selectedServices.forEach(element => {
        services.push(element.id)
      });

      return services.toString()
    },

    print(type) {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      const reportName = this.getReportBasedOnType(type);
      this.selectedItem.serviceIds = this.serialzeServices();
      this.preparePrametersForPrint();
      this.printReport(reportName, this.selectedItem);
    },
  },
};
</script>

<style>

.repots-buttons {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}

span.vs__selected {
  background-color: gray;
}

@media (max-width: 768px) {
  .repots-buttons {
    justify-content: center;
  }
}

.service-report .gb-package {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 3px;
}
.service-report .gb-package label {
  font-size: 14px;
}
b-button-group {
  border-radius: 6px;
}
.service-report .gb-btn-child {
  border: 1px solid gainsboro;
}
.service-report .btnActive {
  background-color: rgba(115, 103, 240, 0.12);
}
</style>
